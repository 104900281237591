/** @format */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;800&family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #000000;

  @font-face {
    font-family: "Skia";
    src: local("Skia"), url("./font/SkiaRegular.ttf") format("truetype");
  }

  font-family: "Poppins", sans-serif;
  font-family: "Skia";
  font-family: "Playfair Display", serif;
}
